<template>
  <v-row class="ma-0 pa-0 fill-height">
    <div class="ma-0 pa-0 fill-height">
      <v-parallax id="main-view" :height="viewport[1]"
                  src="@/assets/img/main.jpg">
        <div class="d-flex justify-space-between"
             :style="`margin-top: 25%`">
          <div class="d-inline-flex flex-column justify-start pl-lg-10 pl-xl-12">
            <h1 class="display-4 font-weight-bold">NORTH IT GROUP</h1>
            <h2 class="display-2 font-weight-bold">Digital solutions for your business</h2>
          </div>
          <div class="d-inline-block" style="max-width: 50%">
            <v-img max-height="182" src="@/assets/img/sponsor.png"></v-img>
          </div>
        </div>
      </v-parallax>
      <div :class="`ma-0 pa-0 wrapper wrapper-${i}`"
           v-for="(item, i) in branches" :key="i"
           :style="`background: #1E2C40;width: ${viewport[0]}px; max-height: ${viewport[1] * branches.length}px`">
        <div data-image class="ma-0 pa-0"
             :style="`height: ${viewport[1]}px; background: url(${require(`@/assets/img/${item.image}`)}) no-repeat; background-size: cover`"></div>
        <div data-description class="d-flex align-center ma-0 pa-12"
             :style="`border-top-left-radius: 30px;border-bottom-left-radius: 30px;height: ${viewport[1]}px`">
          <div :class="`description-${i}`" :style="`width: ${viewport[0] / 5}px`">
            <span class="d-block text-h4 font-weight-bold">{{ item.title }}</span>
            <span class="d-block body-1">{{ item.description }}</span>
          </div>
        </div>
      </div>
      <div class="ma-0 pa-0 clients"
           :style="`height: ${viewport[1]}px; width: ${viewport[0]}px`">
        <div class="clients-description">
          <div class="display-4 font-weight-bold text-uppercase white--text">
            <span data-clientsTitle class="d-block">Our Clients</span>
          </div>

        </div>
        <div class="clients-cards">
          <v-card data-card class="d-flex justify-center align-center client-card"
                  :href="client.website"
                  v-for="(client, i) in clients" :key="i">
            <v-img data-cardImage class="rounded-0" max-width="50%" :src="require(`@/assets/img/${client.image}`)"></v-img>
          </v-card>
        </div>
      </div>
      <div class="ma-0 pa-0 partners"
           :style="`height: ${viewport[1]}px; width: ${viewport[0]}px`">
        <div class="partners-description">
          <div class="display-4 font-weight-bold text-uppercase white--text">
            <span data-partnersTitle class="d-block">Our Partners</span>
          </div>
        </div>
        <div class="partners-cards">
          <v-card data-card class="d-flex justify-center align-center partner-card"
                  :href="partner.website"
                  v-for="(partner, i) in partners" :key="i">
            <v-img data-cardImage class="rounded-0" max-width="50%" :src="require(`@/assets/img/${partner.image}`)"></v-img>
          </v-card>
        </div>
      </div>
      <div class="ma-0 pa-0 contacts primary"
           :style="`height: ${viewport[1]}px; width: ${viewport[0]}px`">
        <div class="first d-flex align-center justify-start display-4 font-weight-bold white--text text-uppercase">
          <span class="social-title">Social Networks</span>
        </div>
        <div class="second">
          <div style="height: 100% !important" class="d-flex flex-column align-center justify-space-around my-auto">
            <v-avatar :data-network="network.dataAttr"
                      @click="openLink(network.link)" style="border: 3px solid #fff"
                      v-for="(network, i) in networks"
                      :key="i"
                      :size="viewport[0] / 10">
              <v-icon color="#fff" :size="viewport[0] / 12">{{ network.icon }}</v-icon>
            </v-avatar>
          </div>
        </div>
        <div class="third">
          <div class="d-flex align-center justify-start flex-column">
              <span class="contacts-title d-block display-4 font-weight-bold white--text text-uppercase my-12 align-self-start">Contacts</span>
              <div class="d-flex align-center justify-start flex-row contact-phone-wrapper align-self-start">
                <v-avatar class="mr-6" size="48" style="border: 3px solid #fff">
                  <v-icon color="#fff" size="32">mdi-phone</v-icon>
                </v-avatar>
                <a href="tel:+4915678573735"
                   class="contact-phone display-2 font-weight-bold white--text text-uppercase">+49 15678573735</a>
              </div>
              <div class="my-6"></div>
              <div class="d-flex align-center justify-start flex-row contact-mail-wrapper align-self-start">
                <v-avatar class="mr-6" size="48" style="border: 3px solid #fff">
                  <v-icon color="#fff" size="32">mdi-email-outline</v-icon>
                </v-avatar>
                <a href="mailto:office@northitgroup.com"
                   class="contact-mail display-2 font-weight-bold white--text text-uppercase">office@northitgroup.com</a>
              </div>
              <div class="my-6"></div>
          </div>
          <div class="d-flex align-center justify-start flex-column contact-subscribe-wrapper align-self-start">
            <span class="display-2 font-weight-bold white--text text-uppercase align-self-start d-block">Subscribe now</span>
            <v-text-field type="email" dark class="d-flex align-center rounded-0 contact-subscribe-input align-self-start my-6"
                          outlined
                          placeholder="Enter your email">
              <template v-slot:append>
                <v-btn large dark icon>
                  <v-icon large>mdi-send</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
        </div>
      </div>
    </div>
  </v-row>
</template>

<script>
import '@/scss/main.view.scss'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/src/all'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
export default {
  name: "Main",
  props: {
    viewport: Array
  },
  data () {
    return {
      branches: [
        {
          image: 'development.jpeg',
          title: 'Website Development',
          description: 'Your website is your figure head to the customer, to the market and to the world. We provide you with a unique and individual website, governed by high-quality web-design and based on the latest technologies. Our proven SEO-expertise will help your web-site attract the right visitors. So, make users fall in love with your web-site and let your unique expertise shine through'
        },
        {
          image: 'software.jpeg',
          title: 'Software Engineering',
          description: 'Digitalization is a precondition for success in external and internal company processes. We provide you with tailor-made IT business solutions, developed by your request and based on our own business analysis. Let’s streamline your internal processes, save operational costs and free up manpower to concentrate on your core business.'
        },
        {
          image: 'outsourcing.jpeg',
          title: 'IT Outsourcing',
          description: 'The number and complexity of IT processes is growing exponentially. Let our professional team be your partner on the journey to success. We’ll meet with you, analyze your needs and compile a team of experienced senior and junior developers especially dedicated to your company. Redirect your time to growth, while our professionals are taking care of your demands.'
        },
        {
          image: 'consulting.jpeg',
          title: 'IT Consulting',
          description: 'The most successful business projects are driven by people who have the vision and influence to initiate the required changes. An extrinsic view and fresh expertise is vital to help business owners identify market changes and avoid problems on time. Our experienced Change Management Team support – and, if required, lead your projects, tackling structural and process changes in the company.'
        }
      ],
      clients: [
        {
          image: 'eagle.png',
          title: 'Eagle Online Marketing',
          description: '',
          website: 'https://eagle-online-marketing.com'
        },
        {
          image: 'morenergy.png',
          title: 'morEnergy',
          description: '',
          website: 'https://morenergy.net'
        },
        {
          image: 'zeppelin.png',
          title: 'ZEPPELIN CAT',
          description: '',
          website: 'https://zeppelin.com.by/'
        },
        {
          image: 'amundas.png',
          title: 'AMUNDAS',
          description: '„Unser Ziel ist, Arbeitsschutz nachhaltig zu gestalten.\n' +
              'Darunter verstehen wir nicht nur die Umsetzung gesetzlicher Vorgaben,\n' +
              'sondern  Betriebsarzt und Fachkraft für Arbeitssicherheit\n' +
              'in wesentliche Prozesse ihrer Organisation zu intergrieren.“',
          website: 'https://www.amundas.de/'
        },
        {
          image: 'sunnydays.png',
          title: 'Sunny Days',
          description: '',
          website: 'https://www.onlineweg.de/sunny-days/'
        },
        {
          image: 'hanskrohn.png',
          title: 'Hans Krohn',
          description: '',
          website: 'https://hanskrohn.com/'
        },
        {
          image: 'villasunlight.png',
          title: 'Villa Sunlight',
          description: '',
          website: 'https://villa-sunlight.com/'
        },
        {
          image: 'wett.png',
          title: 'Wetter Held',
          description: '',
          website: 'https://wetterheld.com/home/'
        },
        {
          image: 'philip.png',
          title: 'Philipp Pagels',
          description: '',
          website: ''
        }
      ],
      partners: [
        {
          image: 'dbwc.png',
          title: 'DBWC',
          description: '',
          website: 'https://www.dbwc-minsk.com'
        },
        {
          image: 'morenergy.png',
          title: 'morEnergy',
          description: '',
          website: 'https://morenergy.net'
        },
        {
          image: 'a1.png',
          title: 'A1',
          description: '',
          website: 'https://www.a1.by'
        },
        {
          image: 'oa.png',
          title: 'Ost-Ausschuss der Deutschen Wirtschaft',
          description: '',
          website: 'https://www.ost-ausschuss.de'
        },
        {
          image: 'rebase.png',
          title: 'Rebase 3',
          description: '',
          website: 'https://www.rebase3.com/en/'
        }
      ],
      networks: [
        {
          icon: 'mdi-facebook',
          link: 'https://web.facebook.com/northitgroup/?_rdc=1&_rdr',
          dataAttr: 'fb'
        },
        {
          icon: 'mdi-linkedin',
          link: 'https://linkedin.com/company/north-it-group',
          dataAttr: 'linkedin'
        },
        {
          icon: 'mdi-instagram',
          link: 'https://www.instagram.com/northitgroup/',
          dataAttr: 'instagram'
        }
      ]
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(CSSRulePlugin)

    const toggle = {
      before: CSSRulePlugin.getRule('.hamburger-inner::before'),
      after: CSSRulePlugin.getRule('.hamburger-inner::after')
    }

    gsap.utils.toArray('.wrapper').forEach((wrapper, i) => {
      gsap.timeline({
            scrollTrigger: {
              trigger: `.wrapper-${i}`,
              scrub: true,
              pin: true,
              pinSpacing: true
            }
          })
          .fromTo(document.querySelector('[data-logo]'), {
            fill: '#fff'
          }, {
            fill: '#1e2c40'
          })
          .fromTo(`.description-${i}`, {
            marginLeft: `${Math.floor(Math.random() * 30 + 20)}%`
          }, {
            marginLeft: 0
          })
          .fromTo(wrapper.querySelector('[data-image]'), {
            width: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0
          }, {
            width: '60%',
            borderBottomRightRadius: '30px',
            borderTopRightRadius: '30px'
          })
          .fromTo(wrapper.querySelector('[data-description]'), {
            backgroundColor: '#fff',
            color: '#1E2C40',
            width: '100%',
          }, {
            backgroundColor: '#1E2C40',
            color: '#fff',
            width: '40%',
          })
          .fromTo(toggle.before, {
            cssRule: {
              backgroundColor: '#fff'
            }
          }, {
            cssRule: {
              backgroundColor: '#1E2C40'
            }
          })
          .fromTo(toggle.after, {
            cssRule: {
              backgroundColor: '#1E2C40'
            }
          }, {
            cssRule: {
              backgroundColor: '#FFF'
            }
          })
          .fromTo(document.querySelector('[data-logo]'), {
            fill: '#1E2C40'
          }, {
            fill: '#FFF'
          })
    })
    gsap.timeline({
          scrollTrigger: {
            trigger: '.clients',
            pin: true,
            scrub: true
          }
        })
        .fromTo(toggle.after, {
          cssRule: {
            backgroundColor: '#fff'
          }
        }, {
          cssRule: {
            backgroundColor: '#1E2C40'
          }
        }).fromTo(toggle.before, {
          cssRule: {
            backgroundColor: '#fff'
          }
        }, {
          cssRule: {
            backgroundColor: '#1E2C40'
          }
        })
        .fromTo(document.querySelector('[data-clientsTitle]'), {
          yPercent: 1500
        }, {
          yPercent: 0
        })
    gsap.utils.toArray('.client-card').forEach((card, i) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: '.clients',
          pin: true,
          scrub: true
        }
      }).fromTo(card, {
        xPercent: 800
      }, {
        xPercent: 0,
        delay: () => 0.2 * i
      })
    })
    gsap.timeline({
          scrollTrigger: {
            trigger: '.partners',
            pin: true,
            scrub: true
          }
        })
        .fromTo(toggle.after, {
          cssRule: {
            backgroundColor: '#1E2C40'
          }
        }, {
          cssRule: {
            backgroundColor: '#FFF'
          }
        }).fromTo(toggle.before, {
          cssRule: {
            backgroundColor: '#1E2C40'
          }
        }, {
          cssRule: {
            backgroundColor: '#FFF'
          }
        })
        .fromTo(document.querySelector('[data-partnersTitle]'), {
          yPercent: 1500
        }, {
          yPercent: 0
        })

    gsap.utils.toArray('.partner-card').reverse().forEach((card, i) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: '.partners',
          pin: true,
          scrub: true
        }
      }).fromTo(card, {
        xPercent: -800
      }, {
        xPercent: 0,
        delay: () => 0.2 * i
      })
    })

        gsap.timeline({
          scrollTrigger: {
            trigger: '.contacts',
            pin: true,
            scrub: true
          }
        })
        .fromTo('.social-title', {
          yPercent: 350
        }, {
          yPercent: 0
        })
        .fromTo(document.querySelector('[data-network="fb"]'), {
          yPercent: 550
        }, {
          yPercent: 0
        })
        .fromTo(document.querySelector('[data-network="linkedin"]'), {
          yPercent: 550
        }, {
          yPercent: 0
        })
        .fromTo(document.querySelector('[data-network="instagram"]'), {
          yPercent: 550
        }, {
          yPercent: 0
        })
        .fromTo(document.querySelector('.contacts-title'), {
          yPercent: 1500
        }, {
          yPercent: 0
        })
        .fromTo(document.querySelector('.contact-phone-wrapper'), {
          yPercent: 2000
        }, {
          yPercent: 0
        })
        .fromTo(document.querySelector('.contact-mail-wrapper'), {
          yPercent: 2000
        }, {
          yPercent: 0
        })
        .fromTo(document.querySelector('.contact-subscribe-wrapper'), {
          yPercent: 2000
        }, {
          yPercent: 0
        })
  },
  methods: {
    openLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>
